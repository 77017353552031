<template>
  <vxe-grid
    :id="uuid"
    :ref="(el) => (instance = el)"
    v-bind="gridOptions"
    :footer-method="footerMethod"
    @current-change="onCurrentChange"
    @page-change="onPageChange"
    @checkbox-change="onCheckboxChange"
    @checkboxAll="onCheckboxAll"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
    <template v-if="!$slots.operate" #operate="{ row }">
      <slot name="operate" :row="row">
        <div class="flex" v-if="isEditing && editingRow == row">
          <button
            class="button rounded-full mr-1 bg-theme-9 text-white"
            title="確認"
            @click.stop="save(row)"
          >
            <FontAwesome icon="check-double" class="w-4 h-4" />
          </button>
          <button
            class="button rounded-full bg-theme-6 text-white"
            title="取消"
            @click.stop="reset(row)"
          >
            <FontAwesome icon="history" class="w-4 h-4" />
          </button>
        </div>
        <div class="flex justify-center" v-if="!isEditing">
          <button
            v-if="canUpdate && decideRowOperable(row, 'update')"
            class="button rounded-full mr-1 bg-blue-600 text-white"
            title="編輯"
            @click.stop="edit(row)"
          >
            <FontAwesome icon="highlighter" class="w-4 h-4" />
          </button>
          <button
            v-else-if="canRead && decideRowOperable(row, 'read')"
            class="button rounded-full mr-1 bg-blue-600 text-white"
            title="詳細資料"
            @click.stop="edit(row)"
          >
            <FontAwesome icon="info" class="w-4 h-4" />
          </button>
          <button
            v-if="canDelete && decideRowOperable(row, 'delete')"
            class="button rounded-full bg-red-600 text-white"
            title="刪除"
            @click.stop="remove(row)"
          >
            <FontAwesome icon="trash" class="w-4 h-4" />
          </button>
        </div>
      </slot>
    </template>
  </vxe-grid>
  <vxe-modal
    :title="`${editingRow && editingRow.Id ? (canUpdate ? '編輯' : '查看') : '新增'}${title}`"
    transfer
    destroy-on-close
    v-bind="lastModalConfig"
    v-model="isModalPopup"
    @show="onResize"
    @close="reset(editingRow, true)"
    @zoom="onResize"
  >
    <template #footer>
      <slot name="modal-footer" />
    </template>
    <slot
      name="modal"
      :row="editingRow || {}"
      :submit="() => save(editingRow)"
      :reset="() => reset(editingRow)"
    >
      <div class="flex h-full">
        <div class="m-auto">
          <h1 class="text-4xl text-gray-600 font-medium leading-none">
            Comming Soon
          </h1>
          <h1 class="text-gray-300 font-medium leading-none">
            ~ The modal template is not available ~
          </h1>
        </div>
      </div>
    </slot>
  </vxe-modal>
</template>

<script lang="ts">
/* eslint-disable */

import CloudFun, { defineComponent, ref, reactive, computed, Sorting, SortOrder, Condition, LogicalConnective, PropType, Operator } from "@cloudfun/core";
import { VxeColumnPropTypes, VxeGridInstance, VxeGridProps, VxeGridPropTypes, VxeModalProps, VXETable, VxeTableConstructor, VxeTableDefines, VxeTablePrivateMethods, VxeTablePropTypes } from "vxe-table";
import { v1 as uuidv1 } from "uuid";
import Sortable from 'sortablejs';
import { nextTick, onUnmounted } from "vue";

export type GridOptions = {
  /** 識別碼 */
  id?: string;
  /** 資料主鍵 */
  rowId?: string;
  /** 尺寸 */
  size?: VxeTablePropTypes.Size;
  /** 標題 */
  title?: string;
  /** 模式 */
  mode?: "inline" | "popup";
  /** 多選 */
  multiselect?: boolean;
  /** 圓角 */
  round?: boolean;
  /** 框線 */
  border?: boolean;
  /** 斑馬紋 */
  stripe?: boolean;
  /** 滑鼠經過欄位時高亮 */
  highlightHoverColumn?: boolean;
  /** 滑鼠經過行列時高亮 */
  highlightHoverRow?: boolean;
  /** 資料行的class */
  rowClassName?: VxeTablePropTypes.RowClassName;
  /** 可改變大小 */
  resizable?: boolean;
  /** 自動調整表格大小 */
  autoResize?: boolean;
  /** 高度 */
  height?: number | string;
  /** 排序設定 */
  sortConfig?: VxeTablePropTypes.SortConfig;
  /** 分頁設定 */
  pagerConfig?: VxeGridPropTypes.PagerConfig;
  /** 列印設定 */
  printConfig?: VxeTablePropTypes.PrintConfig;
  /** 匯出設定 */
  exportConfig?: VxeTablePropTypes.ExportConfig;
  /** 工具列設定 */
  toolbarConfig?: VxeGridPropTypes.ToolbarConfig;
  /** 是否顯示表頭 */
  showHeader?: boolean;
  /** 是否顯示表尾 */
  showFooter?: boolean;
  /** 取得表尾數據之方法 */
  footerMethod?: VxeTablePropTypes.FooterMethod;
  /** 欄未設定。當存在type為checkbox之欄位時將置換選取欄，當存在slots.default之欄位時將置換操作欄 */
  columns: VxeGridPropTypes.Columns;
  /** 非同步設定 */
  promises?: {
    /** 非同步查詢 */
    query?: (params: {
      page: number;
      pageSize: number;
      keyword?: string;
      sortings?: Sorting[];
      condition?: Condition;
    }) => Promise<{ data: any[]; totalCount: number }>;
    /** 查詢所有資料 */
    queryAll?: (params?: {
      keyword?: string;
      sortings?: Sorting[];
      condition?: Condition;
    }) => Promise<any[]>;
    /** 儲存已變更資料 */
    save?: (params: {
      insertRows?: any[];
      updateRows?: any[];
      deleteRows?: any[];
    }) => Promise<void>;
  };
  /** 互動視窗設定 */
  modalConfig?: {
    title?: string;
    width?: number | string;
    height?: number | string;
    showZoom?: boolean;
    resize?: boolean;
    remember?: boolean;
    showHeader?: boolean;
    showFooter?: boolean;
    zIndex?: number;
  };
  /** 可新增資料 */
  canCreate?: boolean;
  /** 可讀取資料 */
  canRead?: boolean;
  /** 可更新資料 */
  canUpdate?: boolean;
  /** 可刪除資料 */
  canDelete?: boolean;
  /** 判斷資料列是否可正常操作 */
  decideRowOperable?: (row: any, operation: 'read' | 'update' | 'delete') => boolean;
  /** 樹狀結構設定 */
  treeConfig?: VxeTablePropTypes.TreeConfig;
  /** 複選框設定 */
  checkboxConfig?: VxeTablePropTypes.CheckboxConfig;
};

export interface TreeConfig {
  parent?: string;
  children?: string;
  indent?: number;
  line?: boolean;
  expandAll?: boolean;
  expandRowKeys?: string[] | number[];
  accordion?: boolean;
  trigger?: "default" | "cell" | "row";
  lazy?: boolean;
  hasChild?: string;
  reserve?: boolean;
  query?: (params: {
    parent: any;
    keyword?: string;
    sortings?: Sorting[];
    condition?: Condition;
  }) => Promise<any[]>;
  toggleMethod?(params: {
    $table: VxeTableConstructor & VxeTablePrivateMethods;
    expanded: boolean;
    row: any;
    column: VxeTableDefines.ColumnInfo;
    columnIndex: number;
    $columnIndex: number;
  }): boolean;
  showIcon?: boolean;
  iconOpen?: string;
  iconClose?: string;
  iconLoaded?: string;
}

export default defineComponent({
  props: {
    /** 識別碼，欄位寬度與位置須以識別碼為索引儲存 */
    id: String,
    /** 傳遞 class */
    class: String,
    /** 資料主鍵 */
    rowId: { type: String, default: "_XID" },
    /** 尺寸 */
    size: { type: String as PropType<VxeTablePropTypes.Size>, default: null },
    /** 標題 */
    title: { type: String, default: "" },
    /** 模式。inline: 嵌入，popup: 彈跳視窗 */
    mode: { type: String as PropType<"inline" | "popup">, default: "popup" },
    /** 多選 */
    multiselect: { type: Boolean, default: true },
    /** Grid 是否以圓角呈現 */
    round: { type: Boolean, default: true },
    /** 欄是否有框線 */
    border: { type: Boolean, default: true },
    /** 列是否以條紋呈現  */
    stripe: { type: Boolean, default: true },
    /** 徘迴時是否高亮顯示該欄 */
    highlightHoverColumn: { type: Boolean, default: true },
    /** 徘迴時是否高亮顯示該列 */
    highlightHoverRow: { type: Boolean, default: true },
    /** 資料行class */
    rowClassNmae: { type: [String, Function] as PropType<VxeTablePropTypes.RowClassName> },
    /** 是否可調整大小 */
    resizable: { type: Boolean, default: true },
    /** 自動調整表格大小 */
    autoResize: { type: Boolean, default: false },
    /** 高度 */
    height: { type: [Number, String] as PropType<number | string> },
    /** 排序設定 */
    sortConfig: {
      type: Object as PropType<VxeTablePropTypes.SortConfig>,
      default: { remote: true, multiple: true },
    },
    /** 分頁設定 */
    pagerConfig: {
      type: Object as PropType<VxeGridPropTypes.PagerConfig>,
      default: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [5, 10, 20],
        layouts: [
          "PrevPage",
          "Jump",
          "PageCount",
          "NextPage",
          "Sizes",
          "Total",
        ],
      },
    },
    /** 列印設定 */
    printConfig: {
      type: Object as PropType<VxeTablePropTypes.PrintConfig>,
      default: {
        modes: ["current", "selected", "all"],
      },
    },
    /** 匯出設定 */
    exportConfig: {
      type: Object as PropType<VxeTablePropTypes.ExportConfig>,
      default: {
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
      },
    },
    /** 工具列設定 */
    toolbarConfig: {
      type: Object as PropType<VxeGridPropTypes.ToolbarConfig>,
    },
    /** 顯示表頭 */
    showHeader: {
      type: Boolean,
      default: true,
    },
    /** 顯示表尾 */
    showFooter: {
      type: Boolean,
      default: false,
    },
    /** 表尾數據函式 */
    footerMethod: {
      type: Function as PropType<VxeTablePropTypes.FooterMethod>,
    },
    /** 所有欄位 */
    columns: {
      type: Object as PropType<VxeGridPropTypes.Columns>,
      required: true,
    },
    /** 所有按鈕 */
    buttons: {
      type: Array as PropType<
        Array<{ name: string; icon: string; onClick: (name: string) => void }>
      >,
      default: [],
    },
    /** 可提供的承諾 */
    promises: {
      type: Object as PropType<{
        query?: (params: {
          page: number;
          pageSize: number;
          keyword?: string;
          sortings?: Sorting[];
          condition?: Condition;
        }) => Promise<{ data: any[]; totalCount: number }>;
        queryAll?: (params?: {
          keyword?: string;
          sortings?: Sorting[];
          condition?: Condition;
        }) => Promise<any[]>;
        save?: (params: {
          insertRows?: any[];
          updateRows?: any[];
          deleteRows?: any[];
        }) => Promise<void>;
      }>,
    },
    /** 互動視窗設定 */
    modalConfig: {
      type: Object as PropType<{
        title?: string;
        width?: number | string;
        height?: number | string;
        showZoom?: boolean;
        resize?: boolean;
        remember?: boolean;
      }>,
      default: {
        width: "70%",
        height: "70%",
        showZoom: true,
        resize: true,
        remember: true,
      },
    },
    /** 可新增 */
    canCreate: { type: Boolean, default: true },
    /** 可讀取 */
    canRead: { type: Boolean, default: true },
    /** 可更新 */
    canUpdate: { type: Boolean, default: true },
    /** 可刪除 */
    canDelete: { type: Boolean, default: true },
    /** 判斷資料列是否可正常操作 */
    decideRowOperable: { type: Function as PropType<(row: any, operation: 'read' | 'update' | 'delete') => boolean>, default: () => true },
    /** 樹狀結構設定 */
    treeConfig: {
      type: Object as PropType<TreeConfig>,
    },
    /** 複選框設定 */
    checkboxConfig: {
      type: Object as PropType<VxeTablePropTypes.CheckboxConfig>,
      default: { reserve: true },
    },
    onReset: Function,
    onRefresh: Function,
    onReload: Function,
    onModalResize: Function,
    onCheckboxChange: Function,
    onCheckboxAll: Function,
    onCurrentRowChanged: Function,
    onPageChanged: Function,
    onAddNewRow: Function,
    onEdit: Function,
    onRemove: Function,
    onRemoveSelectedRows: Function,
    onSave: Function,
    onShangeFilterEvent: Function,
    onExport: Function,
    onPrint: Function,
    onChangeFilterEvent: Function,
  },
  setup(props, { emit, attrs }) {
    // #region Properties

    const uuid = props.id || uuidv1();
    const mode = ref(props.mode);
    const isModalPopup = ref(false);
    const instance = ref({} as VxeGridInstance);
    const loading = ref(false);
    const editingRow = ref<any>(null);
    const keyword = ref("");
    const customFilters = ref<Condition[]>([]);

    // #endregion
    // #region VxeTable Options

    let columns: VxeGridPropTypes.Columns = [];
    if (props.multiselect && !columns.some((e) => e.type && e.type === "checkbox")) {
      columns.push({ type: "checkbox", width: 35, fixed: "left", resizable: false });
    }
    columns = [...columns, ...props.columns];
    if ((props.canCreate || props.canRead || props.canUpdate || props.canDelete) && !columns.some((e) => e.slots && e.slots.default === "operate")) {
      columns.push({ title: "操作", width: 100, fixed: "right", align: "center", resizable: false, slots: { default: "operate" } });
    }

    const gridOptions = reactive({
      size: props.size,
      showHeader: props.showHeader,
      autoResize: props.autoResize,
      height: props.height,
      rowId: props.rowId,
      keepSource: true,
      round: props.round,
      border: props.border,
      stripe: props.stripe,
      highlightCurrentRow: true,
      highlightHoverColumn: props.highlightHoverColumn,
      highlightHoverRow: props.highlightHoverRow,
      rowClassName: props.rowClassNmae,
      resizable: props.resizable,
      loading: loading.value,
      customConfig: {
        storage: true,
        checkMethod: ({ column }) => {
          return column.property?.length > 0 && column.title?.length > 0;
        },
      },
      toolbarConfig: props.toolbarConfig
        ? {
            enabled: true,
            custom: props.toolbarConfig?.custom,
            refresh: props.toolbarConfig?.refresh,
            slots: props.toolbarConfig?.slots,
          }
        : undefined,
      filterConfig: { remote: true },
      sortConfig: { remote: true, multiple: true, ...props.sortConfig },
      editConfig: {
        trigger: "manual",
        mode: "row",
        showIcon: false,
        autoClear: false,
      },
      pagerConfig: props.pagerConfig,
      printConfig: props.printConfig,
      exportConfig: props.exportConfig,
      columns,
      treeConfig: props.treeConfig,
      checkboxConfig: props.checkboxConfig,
      proxyConfig: {
        message: false,
        sort: true,
        filter: true,
        props: { result: "data", total: "totalCount" },
        ajax: {
          query: (params) => {
            const queryParams: {
              page: number;
              pageSize: number;
              keyword: string;
              sortings?: Sorting[];
              condition: Condition;
            } = {
              page: params.page.currentPage,
              pageSize: params.page.pageSize,
              keyword: keyword.value,
              sortings: params.sorts
                .filter((e) => e.property)
                .map(
                  (e) =>
                    new Sorting(
                      e.property,
                      e.order === "desc"
                        ? SortOrder.Descending
                        : SortOrder.Ascending
                    )
                ), // sorts
              condition: new Condition(customFilters.value), // custom filters
            };
            // filters
            const filters = params.filters
              ? params.filters.filter((e) => e.values.length)
              : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach((filter) => {
                const columnCondition = new Condition();
                filter.values.forEach((subFilter) => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And)
                      columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            const queryPagination = props.promises?.query;
            return queryPagination
              ? new Promise((resolve, reject) =>
                  queryPagination(queryParams).then(
                    (payload) => resolve(payload),
                    (reason) => {
                      CloudFun.send("error", {
                        subject: "讀取失敗",
                        content: reason,
                      });
                      reject(reason);
                    }
                  )
                )
              : async () => {
                  return { data: [], totalCount: 0 };
                };
          },
          queryAll: (params) => {
            const queryParams: {
              keyword: string;
              sortings?: Sorting[];
              condition: Condition;
            } = {
              keyword: keyword.value,
              sortings: params.sorts
                .filter((e) => e.property)
                .map(
                  (e) =>
                    new Sorting(
                      e.property,
                      e.order === "desc"
                        ? SortOrder.Descending
                        : SortOrder.Ascending
                    )
                ), // sorts
              condition: new Condition(customFilters.value), // custom filters
            };
            // filters
            const filters = params.filters
              ? params.filters.filter((e) => e.values.length)
              : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach((filter) => {
                const columnCondition = new Condition();
                filter.values.forEach((subFilter) => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And)
                      columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            const queryAll = props.promises?.queryAll;
            return queryAll
              ? new Promise((resolve, reject) =>
                  queryAll(queryParams).then(
                    (payload) => resolve(payload),
                    (reason) => {
                      CloudFun.send("error", {
                        subject: "讀取失敗",
                        content: reason,
                      });
                      reject(reason);
                    }
                  )
                )
              : async () => {
                  return [];
                };
          },
          delete: (params) => {
            const deleteRows = props.promises?.save;
            return deleteRows
              ? new Promise((resolve, reject) =>
                  deleteRows({ deleteRows: params.body.removeRecords }).then(
                    (payload) => resolve(payload),
                    (reason) => {
                      CloudFun.send("error", {
                        subject: "刪除失敗",
                        content: reason,
                      });
                      reject(reason);
                    }
                  )
                )
              : async () => {
                  return undefined;
                };
          },
          save: (params) => {
            const saveParams = {
              insertRows: params.body.insertRecords,
              updateRows: params.body.updateRecords,
              deleteRows: params.body.removeRecords,
            };
            const saveRows = props.promises?.save;
            return saveRows
              ? new Promise((resolve, reject) =>
                  saveRows(saveParams).then(
                    (payload) => {
                      editingRow.value = null;
                      resolve(payload);
                    },
                    (reason) => {
                      CloudFun.send("error", {
                        subject: "保存失敗",
                        content: reason,
                      });
                      reject(reason);
                    }
                  )
                )
              : async () => {
                  return undefined;
                };
          },
        },
      },
    } as VxeGridProps);

    if (
      gridOptions.treeConfig &&
      props.treeConfig?.parent &&
      props.treeConfig?.query
    ) {
      const parent = props.treeConfig.parent;
      const query = props.treeConfig.query;
      gridOptions.treeConfig.loadMethod = (params) => {
        const queryParams: {
          parent: any;
          keyword: string;
          sortings?: Sorting[];
          condition: Condition;
        } = {
          parent: params.row[parent],
          keyword: keyword.value,
          sortings: params.$table
            .getSortColumns()
            .filter((e) => e.property)
            .map(
              (e) =>
                new Sorting(
                  e.property,
                  e.order === "desc"
                    ? SortOrder.Descending
                    : SortOrder.Ascending
                )
            ), // sorts
          condition: new Condition(customFilters.value), // custom filters
        };
        // filters
        let filters = params.$table
          .getCheckedFilters()
          .filter((e) => e.values.length);
        if (filters && filters.length) {
          const gridCondition = new Condition();
          // colum filters
          filters.forEach((filter) => {
            const columnCondition = new Condition();
            filter.values.forEach((subFilter) => {
              if (subFilter && subFilter instanceof Condition) {
                const condition = subFilter as Condition;
                if (condition.connective === LogicalConnective.And)
                  columnCondition.and(condition);
                else columnCondition.or(condition);
              }
            });
            gridCondition.and(columnCondition);
          });
          queryParams.condition.and(gridCondition);
        }
        return query(queryParams);
      };
    }

    // #endregion
    // #region VxeModal Options

    const lastModalConfig: VxeModalProps = {
      showZoom: true,
      resize: true,
      remember: true,
      ...props.modalConfig,
    };

    if (
      typeof lastModalConfig.width === "string" &&
      lastModalConfig.width.endsWith("%")
    ) {
      const percentage = Number.parseInt(
        lastModalConfig.width.substr(0, lastModalConfig.width.length - 1)
      );
      lastModalConfig.width = window.innerWidth * (percentage / 100);
    }
    if (
      typeof lastModalConfig.height === "string" &&
      lastModalConfig.height.endsWith("%")
    ) {
      const percentage = Number.parseInt(
        lastModalConfig.height.substr(0, lastModalConfig.height.length - 1)
      );
      lastModalConfig.height = window.innerHeight * (percentage / 100);
    }

    // #endregion
    // #region Computed Properties

    const selectedRows = computed(() => instance.value.getCheckboxRecords());
    const isRowSelected = computed(() => selectedRows.value.length > 0);
    const isEditing = computed(
      () =>
        editingRow.value != null &&
        instance.value.isActiveByRow(editingRow.value)
    );

    // #endregion
    // #region Methods

    const reset = (row?: any, isClosing = false) => {
      if (!row) row = editingRow.value;
      const action = () => {
        switch (mode.value) {
          case "inline":
            instance.value.clearActived().then(() => {
              if (instance.value.isInsertByRow(row)) instance.value.remove(row);
              else if (instance.value.isUpdateByRow(row))
                instance.value.revertData(row);
              editingRow.value = null;
            });
            break;
          case "popup":
            if (instance.value.isInsertByRow(row)) {
              if (isClosing && !row.Id) instance.value.remove(row);
              else for (const key of Object.keys(row)) delete row[key];
            } else instance.value.revertData(row);
            if (isClosing) editingRow.value = null;
            break;
        }
      };
      if (props.onReset) emit("reset", row, action);
      else action();
    };

    const edit = (row: any) => {
      if (!props.canCreate && !props.canUpdate && !props.canRead)
        return;
      editingRow.value = row;
      const action = () => {
        switch (mode.value) {
          case "inline":
            return instance.value.setActiveRow(row);
          case "popup":
            isModalPopup.value = true;
            break;
        }
      };
      if (props.onEdit) emit("edit", row, action);
      else action();
    };

    const addNewRow = (row?: any) => {
      if (!props.canCreate || editingRow.value) return;
      if (row === undefined) row = {};
      const action = () => {
        instance.value.insert(row).then((data) => edit(data.row));
      };
      if (props.onAddNewRow) emit("addNewRow", row, action);
      else action();
    };

    const save = (row?: any) => {
      const {
        insertRecords,
        updateRecords,
        removeRecords,
      } = instance.value.getRecordset();
      if (!props.canCreate && insertRecords.length) return;
      if (!props.canUpdate && updateRecords.length) return;
      if (!props.canDelete && removeRecords.length) return;
      const action = () => {
        const key = props.rowId || "_XID";
        if (props.mode === "popup" && row) {
          if (instance.value.isInsertByRow(row)) {
            if (!insertRecords.find((e) => e[key] === row[key]))
              insertRecords.push(row);
          } else {
            if (!updateRecords.find((e) => e[key] === row[key]))
              updateRecords.push(row);
          }
        }
        let needSave =
          insertRecords.length || updateRecords.length || removeRecords.length;
        needSave &&=
          row &&
          (insertRecords.some((e) => e[key] === row[key]) ||
            updateRecords.some((e) => e[key] === row[key]) ||
            removeRecords.some((e) => e[key] === row[key]));
        const isRowEditing = row && editingRow.value === row;
        if (needSave) {
          switch (props.mode) {
            case "inline":
              instance.value.commitProxy("save").then(() => {
                if (isRowEditing && editingRow.value === row) edit(row);
                else {
                  isModalPopup.value = false;
                  editingRow.value = null;
                }
              });
              break;
            case "popup":
              if (props.promises?.save) {
                gridOptions.loading = true;
                props.promises
                  .save({
                    insertRows: insertRecords,
                    updateRows: updateRecords,
                    deleteRows: removeRecords,
                  })
                  .then(
                    () => {
                      instance.value
                        .commitProxy("query")
                        .then(() => {
                          isModalPopup.value = false;
                          editingRow.value = null;
                        })
                        .finally(() => {
                          gridOptions.loading = false;
                        });
                    },
                    (failure: any) => {
                      let subject: string;
                      let content: string;
                      if (Array.isArray(failure.payload)) {
                        failure.payload.forEach((e: any) => {
                          switch (e.action) {
                            case "create":
                              subject = "新增失敗";
                              break;
                            case "update":
                              subject = "修改失敗";
                              break;
                            case "delete":
                              subject = "刪除失敗";
                              break;
                            default:
                              subject = "未知問題";
                              break;
                          }
                          CloudFun.send("error", {
                            subject,
                            content: e.message,
                          });
                        });
                      } else {
                        content = failure.message;
                        switch (failure.action) {
                          case "create":
                            subject = "新增失敗";
                            break;
                          case "update":
                            subject = "修改失敗";
                            break;
                          case "delete":
                            subject = "刪除失敗";
                            break;
                          default:
                            switch (failure.status) {
                              case 401: 
                                subject = "授權失敗"; 
                                content = "請重新登入";
                                break;
                              default:
                                subject = "未知問題";
                                content = failure.message
                                break;
                            }
                            break;
                        }
                        CloudFun.send("error", {
                          subject,
                          content,
                        });
                      }
                      gridOptions.loading = false;
                    }
                  );
              }
              break;
          }
        } else if (!row || row === editingRow.value) {
          isModalPopup.value = false;
          editingRow.value = null;
        }
      };
      if (props.onSave)
        emit("save", { insertRecords, updateRecords, removeRecords }, action);
      else action();
    };

    const remove = (row: any) => {
      if (!props.canDelete) return;
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        const action = () => {
          gridOptions.loading = true;
          const deleteRows = props.promises?.save;
          if (deleteRows)
            deleteRows({ deleteRows: [row] }).then(
              () => {
                instance.value.commitProxy("query").finally(() => {
                  gridOptions.loading = false;
                });
              },
              (failure: any) => {
                if (Array.isArray(failure)) {
                  failure.forEach((e: any) =>
                    CloudFun.send("error", {
                      subject: "刪除失敗",
                      content: e.message,
                    })
                  );
                } else
                  CloudFun.send("error", {
                    subject: "刪除失敗",
                    content: failure.message || failure,
                  });
                gridOptions.loading = false;
              }
            );
        };
        if (props.onRemove) emit("remove", row, action);
        else action();
      }
    };

    const removeSelectedRows = () => {
      if (!props.canDelete) return;
      const rows = instance.value.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        const action = () => {
          instance.value.commitProxy("delete");
        };
        if (props.onRemoveSelectedRows)
          emit("removeSelectedRows", rows, action);
        else action();
      }
    };

    const changeFilterEvent = (
      event: Event,
      option: VxeColumnPropTypes.Filter,
      panel: any,
      toogleChecked?: boolean
    ) => {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value)
          option.value = new Condition("Name", Operator.Contains);
        option.value.value = option.data;
      } else option.value = undefined;
      const action = () => {
        if (toogleChecked) panel.changeOption(event, option.checked, option);
      };
      if (props.onChangeFilterEvent) emit("changeFilterEvent", option, action);
      else action();
    };

    const onExport = async (config?: VxeTablePropTypes.ExportConfig) => {
      config = { ...gridOptions.exportConfig, ...config };
      if (config.mode === "all" && props.promises?.queryAll)
        config.data = await props.promises.queryAll();
      const action = async () => await instance.value.exportData(config);
      if (props.onExport) emit("export", config, action);
      else await action();
    };

    const onPrint = async (config?: VxeTablePropTypes.PrintConfig) => {
      config = { ...gridOptions.printConfig, ...config };
      if (config.mode === "all" && props.promises?.queryAll)
        config.data = await props.promises.queryAll();
      const action = () => {
        instance.value.print(config);
      };
      if (props.onPrint) emit("print", config, action);
      else action();
    };

    const refresh = async () => {
      const action = async () => await instance.value.commitProxy("query");
      if (props.onRefresh) emit("refresh", action);
      else await action();
    };

    const reload = async () => {
      const action = async () => await instance.value.commitProxy("reload");
      if (props.onReload) emit("reload", action);
      else await action();
    };

    const onResize = (message: any) => {
      const style = message.$modal.getBox().style;
      emit(
        "modalResize",
        {
          width: Number.parseFloat(style.width),
          height: Number.parseFloat(style.height),
          top: Number.parseFloat(style.top),
          left: Number.parseFloat(style.left),
        },
        () => {
          /* nop */
        }
      );
    };

    const onCheckboxChange = ({ checked, records, reserves, row }: any) => emit("checkboxChange", { checked, records, reserves, row });

    const onCheckboxAll = ({ checked, records, reserves }: any) => emit("checkboxAll", { checked, records, reserves });

    const onCurrentChange = ({ row }: any) => emit("currentRowChanged", row);

    const onPageChange = ({ currentPage, pageSize }: { currentPage: number, pageSize: number }) => emit("pageChanged", currentPage, pageSize);

    const setCheckboxRow = (rows: any, checked: boolean) => instance.value.setCheckboxRow(rows, checked);

    const getData = () => instance.value.getData();

    const clearCheckboxRow = () => instance.value.clearCheckboxRow();

    const clearCheckboxReserve = () => instance.value.clearCheckboxReserve();

    // #endregion

    let sortable2: any

    const columnDrop2 = () => {
      const $grid = instance.value;
      sortable2 = Sortable.create($grid.$el.querySelector('.body--wrapper>.vxe-table--header .vxe-header--row'), {
        handle: '.vxe-header--column:not(.col--fixed)',
        onEnd: (sortableEvent: any) => {
          // const targetThElem = sortableEvent.item
          // const newIndex = sortableEvent.newIndex as number
          // const oldIndex = sortableEvent.oldIndex as number
          // const { fullColumn, tableColumn } = $grid.getTableColumn()
          // const wrapperElem = targetThElem.parentNode as HTMLElement
          // const newColumn = fullColumn[newIndex]
          // if (newColumn.fixed) {
          //   // 错误的移动
          //   const oldTrElement = wrapperElem.children[oldIndex] as HTMLElement
          //   if (newIndex > oldIndex) {
          //     wrapperElem.insertBefore(targetThElem, oldTrElement)
          //   } else {
          //     wrapperElem.insertBefore(oldTrElement, targetThElem)
          //   }
          //   return VXETable.modal.message({ content: '固定列不允许拖动！', status: 'error' })
          // }
          // // 转换真实索引
          // const oldColumnIndex = $grid.getColumnIndex(tableColumn[oldIndex])
          // const newColumnIndex = $grid.getColumnIndex(tableColumn[newIndex])
          // // 移动到目标列
          // const currRow = fullColumn.splice(oldColumnIndex, 1)[0]
          // fullColumn.splice(newColumnIndex, 0, currRow)
          // $grid.loadColumn(fullColumn)
        }
      })
    }

    let initTime: any
    nextTick(() => {
      if (!gridOptions.showHeader) return;
      // 加载完成之后在绑定拖动事件
      initTime = setTimeout(() => {
        columnDrop2()
      }, 500)
    })

    onUnmounted(() => {
      clearTimeout(initTime)
      if (sortable2) {
        sortable2.destroy()
      }
    })

    return {
      instance,
      lastModalConfig,
      gridOptions,
      loading,
      isEditing,
      editingRow,
      selectedRows,
      isRowSelected,
      isModalPopup,
      keyword,
      customFilters,
      reset,
      edit,
      addNewRow,
      save,
      remove,
      removeSelectedRows,
      changeFilterEvent,
      onExport,
      onPrint,
      refresh,
      reload,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      onResize,
      onCurrentChange,
      onPageChange,
      uuid,
      setCheckboxRow,
      getData,
      onCheckboxChange,
      onCheckboxAll,
      clearCheckboxRow,
      clearCheckboxReserve,
    };
  },
});
</script>
